import { createGtm } from '@gtm-support/vue-gtm'

export const GtmPlugin = createGtm({
  id: import.meta.env.VITE_GTM_ID,
  queryParams: {
    gtm_auth: import.meta.env.VITE_GTM_AUTH_TOKEN,
    gtm_preview: import.meta.env.VITE_GTM_ENV,
    gtm_cookies_win: 'x',
  },
  defer: false,
  compatibility: false,
  nonce: '2726c7f26c',
  enabled: import.meta.env.VITE_GTM_ENABLED,
  debug: true,
  loadScript: true,
  trackOnNextTick: true,
})
