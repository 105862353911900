function cjInit(src) {
  if (import.meta.env.VITE_APP_ENV === 'production') {
    ;(function (a, b, c, d) {
      a = src
      b = document
      c = 'script'
      d = b.createElement(c)
      d.src = a
      d.type = 'text/java' + c
      d.async = true
      d.id = 'cjapitag'
      a = b.getElementsByTagName(c)[0]
      a.parentNode.insertBefore(d, a)
    })()
  }
}

const useCj = (app, store) => {
  const cj = window.cj || {}

  app.config.globalProperties.cj = cj
  app.config.globalProperties.cjInit = cjInit

  app.provide('cj', cj)
  app.provide('cjInit', cjInit)

  if (store) {
    store.cj = cj
    store.cjInit = cjInit
  }
}

export { useCj }
